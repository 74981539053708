import {
  CloseIcon,
  DescriptionOutlined,
  MoreVert,
  Button,
  IconButton,
  Input,
  Menu,
  MenuItem,
  Select as SelectUIKit,
  Flex,
  InfoOutlined,
  Tooltip,
} from 'ui-kit';
import Switch from '@mui/material/Switch';
import {
  ActionsEnum,
  ActionValueCriteriaEnum,
  DocumentSourceEnum,
  KeyTypesEnum,
  type Variable,
  VariableTypeEnum,
  type TemplateData,
  type WorkflowAction,
  type MultiSelectVariable,
  // isVariableAllowedToAddInInput,
  type WorkflowNode,
  type ScrapeVariable,
  type DocumentSource,
  SourceVariable,
  VariableString,
  parseTemplateString,
  SourceTypeEnum,
  type MultiChoiceVariable,
  type SelectVariable,
  type SelectOption,
  type Rule,
  WorkflowImageNode,
} from 'types-shared';
import type { SelectedAction } from './ActionsList';
import { EditorStore } from '../../store/EditorState';
import { useTransformApiReq, useUploadFiles } from '../../hooks';
import {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
  type ChangeEvent,
} from 'react';
import { FeatureFlag } from '../../../../utils/constants';
import { useSearchParams } from 'react-router-dom';
import {
  VariableInput,
  type VariableInputRef,
} from '../VariableTypes/VariableInput';
import values from 'lodash/values';
import { pickFromListOptions, removeNode } from '../../utils/helper';
import { type SelectChangeEvent } from '@mui/material/Select';
import { handleException } from 'sentry-browser-shared';
import { clsx } from 'clsx';
import MultiSelect from '../VariableTypes/MultiSelect';
import { ActionHeader } from './ActionHeader';
import { MultipleChoice } from '../VariableTypes/MultipleChoice';
import { Select } from '../Select';
import LinearProgress from '@mui/material/LinearProgress';
import { VariableChip } from '../../../../components/VariableChip';
import { ConditionalField } from '../Conditions/ConditionalField';
import { isAdmin } from '../../../../utils/env';
import { imageNodeEventChannel } from '../NodeElement/SelectedImageNodeContent';
import {
  formatFileSize,
  openAddVariableModal,
  openPreviewVariableModal,
  useFeatureFlag,
} from '../../../../utils/helper';

const actionTypeToTitleMapping: Record<string, string> = {
  [ActionsEnum.MultiChoice]: 'Multiple Choice',
  [ActionsEnum.UploadDocument]: 'File upload',
  [ActionsEnum.SwitchTab]: 'Switch tab',
  [ActionsEnum.PickFromList]: 'Pick from list',
  [ActionsEnum.MultiSelect]: 'Multi Select',
  [ActionsEnum.MagicLoop]: 'Begin Magic Loop',
};

const keyPressOptions = Object.values(KeyTypesEnum) as string[];

const onActionHover = (targetId: string) => {
  imageNodeEventChannel.emit('onActionHover', targetId);
};

const onBlur = () => {
  imageNodeEventChannel.emit('onBlur', null);
};

const customInstructionsKey = pickFromListOptions[2];

interface Props {
  i: number;
  action: WorkflowAction;
  setSelectedAction: (val: null | SelectedAction) => void;
}

function EditAction({ i, action, setSelectedAction }: Props) {
  const {
    datasourceMetadata,
    variables: variablesMap,
    globalVariables: globalVariablesMap = {},
    updateVariable,
    tableData,
    addVariable,
    updateImageNodeAction,
    selectedNode,
    nodes,
    setSelectedNode,
    workflowId,
    targets,
    edges,
    setNodes,
    setEdges,
  } = EditorStore();
  const [, setSearchParams] = useSearchParams();

  const { mutateAsync: transformApiReq, status: transformApiReqStatus } =
    useTransformApiReq(variablesMap);

  const { mutateAsync: uploadFiles } = useUploadFiles();

  const onTransformApiReq = useCallback(
    async (prompt: TemplateData, textToTransform: string) => {
      if (prompt.length > 0 && textToTransform) {
        const value = await transformApiReq({
          data: textToTransform,
          prompt,
        });
        return value?.processedData;
      }
      return undefined;
    },
    [transformApiReq],
  );

  const onUploadFile = useCallback(
    async (file: File) => {
      if (!workflowId) {
        throw new Error('Workflow ID is not defined');
      }
      const fileIds = await uploadFiles({ files: [file], workflowId });
      return { fileId: fileIds[0] };
    },
    [uploadFiles, workflowId],
  );

  const selectionByConditionsEnabled = useFeatureFlag(
    FeatureFlag.SelectionByConditions,
  );

  const { variableId, actionType, criteria, targetId, rules } = action;
  const selectedAction = useMemo(() => {
    const node = nodes.find(({ id }) => id === selectedNode);
    if (!node || !WorkflowImageNode.safeParse(node).success) {
      return null;
    }
    return (node as WorkflowImageNode).data.actionData[action.id];
  }, [action.id, nodes, selectedNode]);
  const continueOnFail = Boolean(selectedAction?.options?.continueOnFail);
  const hasDatasource = Boolean(datasourceMetadata);
  const variable = variableId
    ? variablesMap[variableId] ?? globalVariablesMap[variableId]
    : null;
  const target = targets[targetId ?? ''];
  const isArbitraryAction = actionType === ActionsEnum.Arbitrary;

  const inputVariableInputRef = useRef<VariableInputRef>();
  const tabVariableInputRef = useRef<VariableInputRef>();

  const [selectedTabVariableId, setSelectedTabVariableId] = useState<string>(
    variableId ?? '',
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const onMenuClose = () => {
    setAnchorEl(null);
  };
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [pickedFile, setPickedFile] = useState<{
    name: string;
    size?: string;
    uploading?: boolean;
  } | null>(
    variable?.type === VariableTypeEnum.Document &&
      variable.data.source === DocumentSourceEnum.AWS &&
      variable.data.s3Ref?.fileName
      ? {
          name: variable.data.s3Ref.fileName,
          uploading: false,
        }
      : null,
  );

  const tabVariableOptions = useMemo(() => {
    return values(variablesMap)
      .filter(({ type }: Variable) => type === VariableTypeEnum.Tab)
      .map((_var) => ({
        text: _var.name ?? 'Unnamed tab',
        value: _var.id,
      }));
  }, [variablesMap]);

  const [selectionType, setSelectionType] =
    useState<ActionValueCriteriaEnum | null>(criteria ?? null);

  const isDownloadAction =
    actionType === ActionsEnum.Download ||
    (actionType === ActionsEnum.Click && action.options?.download);

  const isMagicLoopAction = actionType === ActionsEnum.MagicLoop;

  const isDocumentScrape = useMemo(() => {
    if (
      variable &&
      variable.type === VariableTypeEnum.Document &&
      variable.data.source === DocumentSourceEnum.Execution
    ) {
      return true;
    }
    return false;
  }, [variable]);

  const actionTitle = useMemo(() => {
    if (Boolean(isDownloadAction) || isDocumentScrape) {
      return 'Download';
    }

    const processedVariableName =
      variable?.name === 'credential' ? 'Credential' : variable?.name;
    return `${actionTypeToTitleMapping[actionType] ?? actionType}${processedVariableName ? `: ${processedVariableName}` : ''}`;
  }, [actionType, variable?.name, isDownloadAction, isDocumentScrape]);

  const onDetermineSelectionType =
    (newSelectionType: ActionValueCriteriaEnum | null) => () => {
      setSelectionType(newSelectionType);

      onMenuClose();
    };

  const {
    isScrape,
    isSelect,
    isMultiChoice,
    isInput,
    isSwitchTab,
    isNewTab,
    isPickFromList,
    isKeyPress,
    isMultiSelect,
  } = useMemo(
    () => ({
      isSelect: actionType === ActionsEnum.Select,
      isScrape: actionType === ActionsEnum.Scrape,
      isMultiChoice: actionType === ActionsEnum.MultiChoice,
      isInput:
        actionType === ActionsEnum.Input ||
        actionType === ActionsEnum.Arbitrary,
      isSwitchTab: actionType === ActionsEnum.SwitchTab,
      isNewTab: actionType === ActionsEnum.NewTab,
      isPickFromList: actionType === ActionsEnum.PickFromList,
      isKeyPress:
        actionType === ActionsEnum.KeyPress ||
        actionType === ActionsEnum.KeyUnpress,
      isMultiSelect: actionType === ActionsEnum.MultiSelect,
    }),
    [actionType],
  );

  const variableIsEditable =
    !isScrape &&
    selectionType !== ActionValueCriteriaEnum.Condition &&
    !isDownloadAction &&
    !isDocumentScrape;

  useEffect(() => {
    if (targetId) {
      onActionHover(targetId);
    }

    return () => {
      onBlur();
    };
  }, [targetId]);

  const { initialVariableData, initialVariableName } = useMemo(() => {
    const payload: {
      initialVariableData: TemplateData;
      initialVariableName: string;
    } = {
      initialVariableData: [],
      initialVariableName: '',
    };

    const initialVariable = variableId
      ? variablesMap[variableId] ?? globalVariablesMap[variableId]
      : null;

    if (initialVariable) {
      const data: TemplateData = (() => {
        switch (initialVariable.type) {
          case VariableTypeEnum.Document:
          case VariableTypeEnum.Scrape:
          case VariableTypeEnum.Query:
          case VariableTypeEnum.Source:
            return [];
          case VariableTypeEnum.Tab:
            return initialVariable.data.url;
          default:
            return initialVariable.data;
        }
      })();

      payload.initialVariableData = data;
      payload.initialVariableName = initialVariable.name ?? '';
    }

    return payload;
  }, [variablesMap, globalVariablesMap, variableId]);

  const [variableData, setVariableData] =
    useState<TemplateData>(initialVariableData);
  const [variableName, setVariableName] = useState<string>(initialVariableName);

  useEffect(() => {
    setVariableData(initialVariableData);
    setVariableName(initialVariableName);
  }, [initialVariableData, initialVariableName]);

  const saveVariableChanges = (updatedAction?: WorkflowAction) => {
    let updatedVariable = {
      ...variable,
    };

    if (Boolean(isDownloadAction) || isDocumentScrape) {
      updatedVariable = {
        ...updatedVariable,
        name: variableName,
      };
    } else if (actionType === ActionsEnum.MultiChoice) {
      const selectedChoiceIx = variableData[0] ? Number(variableData[0]) : null;
      updatedVariable = {
        ...updatedVariable,
        selectedChoiceIx,
        ...(selectedChoiceIx !== null
          ? { data: [String(selectedChoiceIx)] }
          : {}),
      };
    } else if (actionType === ActionsEnum.NewTab) {
      updatedVariable = {
        ...updatedVariable,
        name: (variableName || variable?.name) ?? '',
        data: {
          url: variableData,
        },
      };
    } else if (actionType === ActionsEnum.MultiSelect) {
      const newVariable = updatedVariable as MultiSelectVariable;
      updatedVariable = {
        ...newVariable,
        data:
          selectionType === ActionValueCriteriaEnum.Variable
            ? variableData
            : [],
        multiSelectedOptions:
          selectionType !== ActionValueCriteriaEnum.Variable
            ? newVariable.multiSelectedOptions
            : [],
      };
    } else if (actionType === ActionsEnum.SwitchTab) {
      // no variable to update
      // just need to update the action directly
    } else {
      updatedVariable = {
        ...updatedVariable,
        data: variableData,
      };
    }
    updateVariable(updatedVariable as Variable);
    if (
      selectedNode &&
      !isMagicLoopAction &&
      !isDownloadAction &&
      !isDocumentScrape &&
      (Boolean(selectionType) || isMultiSelect || isSwitchTab)
    ) {
      updateImageNodeAction(selectedNode, {
        ...(updatedAction ?? action),
        criteria: selectionType ?? undefined,
        variableId: isSwitchTab ? selectedTabVariableId : variableId,
      });
    }
  };

  const onAddNewVariable = (indexForVariableInsert?: number) => {
    saveVariableChanges();
    openAddVariableModal({
      insertVariable(newVariable: Variable) {
        inputVariableInputRef.current?.addVariable(
          newVariable,
          indexForVariableInsert,
        );
      },
    });
  };

  const onPreviewVariable = (variableID: string) => {
    openPreviewVariableModal({
      variableId: variableID,
    });
  };

  const onPreviewVariableV2 = (vId: string) => {
    !isScrape && saveVariableChanges();
    onPreviewVariable(vId);
  };

  const onEditClose = () => {
    setSelectedAction(null);
  };

  const selectedNodeData: WorkflowNode | undefined = useMemo(() => {
    return nodes.find((_node) => _node.id === selectedNode);
  }, [nodes, selectedNode]);

  const selectedVariable = useMemo(() => {
    const id = variableId;
    return id
      ? variablesMap[id] ?? globalVariablesMap[id]
      : (variable as ScrapeVariable);
  }, [variableId, variablesMap, globalVariablesMap, variable]);

  const pickFromListValue = useMemo(() => {
    if (!variable || !('data' in variable) || !Array.isArray(variable.data)) {
      return undefined;
    }
    return (variable.data as TemplateData)[0] as string;
  }, [variable]);

  const pickFromListType = useMemo(() => {
    if (!pickFromListValue) {
      return pickFromListOptions[0];
    }
    return !pickFromListOptions.includes(pickFromListValue)
      ? customInstructionsKey
      : pickFromListValue;
  }, [pickFromListValue]);

  const [localSource, setLocalSource] = useState<DocumentSource>(
    variable?.type === VariableTypeEnum.Document
      ? variable.data.source
      : DocumentSourceEnum.AWS,
  );
  const handleOnSelectSource = (event: SelectChangeEvent) => {
    if (!event.target.value || variable?.type !== VariableTypeEnum.Document)
      return;

    const source = event.target.value as DocumentSource;

    updateVariable({
      ...variable,
      data: {
        ...variable.data,
        source,
      },
    });
    setLocalSource(source);
  };

  const handleOnPickFromListSelect = (event: SelectChangeEvent) => {
    const type = event.target.value;
    if (selectedNode) {
      updateImageNodeAction(selectedNode, {
        ...action,
        options: {
          ...(action.options ?? {}),
          sitl: type !== customInstructionsKey,
        },
      });
    }
    updateVariable({
      ...variable,
      data: [event.target.value],
    } as Variable);
  };

  const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    if (variable?.type !== VariableTypeEnum.Document) return;

    try {
      const file = event.target.files?.[0];

      if (file) {
        const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg'];
        if (!allowedTypes.includes(file.type)) {
          return;
        }

        setPickedFile({
          name: file.name,
          size: formatFileSize(file.size),
          uploading: true,
        });

        const { fileId } = await onUploadFile(file);

        updateVariable({
          ...variable,
          data: {
            ...variable.data,
            source: DocumentSourceEnum.AWS,
            s3Ref: {
              fileId,
              fileName: file.name,
            },
          },
        });
      }
    } finally {
      setPickedFile((p) => ({
        ...p,
        uploading: false,
      }));
    }
  };

  const sourceType = useMemo(() => {
    const sourceVariable = Object.values(variablesMap).find(
      (v) => SourceVariable.safeParse(v).success,
    ) as SourceVariable | undefined;
    return sourceVariable?.data.sourceType;
  }, [variablesMap]);

  const switchActionPreview = useMemo(() => {
    if (actionType !== ActionsEnum.SwitchTab) return null;

    const selectedTabVar =
      variablesMap[selectedTabVariableId] ??
      globalVariablesMap[selectedTabVariableId];
    if (selectedTabVar.type !== VariableTypeEnum.Tab) return null;

    return VariableString.parse(
      parseTemplateString({
        data: selectedTabVar.data.url,
        variableMap: variablesMap,
        handleException,
      }),
    );
  }, [actionType, selectedTabVariableId, variablesMap, globalVariablesMap]);

  const onDeleteNode = () => {
    if (!selectedNode) return;
    const { nodes: filteredNodes, edges: filteredEdges } = removeNode(
      nodes,
      edges,
      selectedNode,
    );
    setSearchParams({});
    setSelectedNode(null);
    setNodes(filteredNodes);
    setEdges(filteredEdges);
  };

  if (!variable) {
    handleException(new Error(), {
      name: 'Variable not found',
      source: 'Editor/EditAction',
      extra: {
        variableId,
        actionType,
        selectedNode,
      },
    });
    return null;
  }

  return (
    <>
      <ActionHeader
        handleOnClose={onEditClose}
        node={selectedNodeData}
        selectedAction={action}
        selectedActionIndex={i}
        selectedVariable={selectedVariable}
        setSelectedNode={setSelectedNode}
        onDeleteNode={onDeleteNode}
      />
      <div className="flex-1 flex flex-col">
        <div className="flex justify-between items-center">
          <h2 className="flex items-center space-x-3 w-full">
            {!isMagicLoopAction ? (
              <span className="text-xs text-white rounded-full h-6 w-6 flex justify-center items-center bg-gray-800">
                {i}
              </span>
            ) : null}
            <span className="font-medium text-lg w-full truncate">
              {actionTitle}
            </span>
          </h2>
          {!isScrape && !isPickFromList && !isMagicLoopAction ? (
            <IconButton
              className="!p-0"
              onClick={(e) => {
                setAnchorEl(e.target as HTMLButtonElement);
              }}
            >
              <MoreVert className="!w-5 !h-5 text-black" />
            </IconButton>
          ) : null}
        </div>
        {!isScrape &&
        !isPickFromList &&
        !isDownloadAction &&
        !isDocumentScrape ? (
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            onClose={onMenuClose}
            open={Boolean(anchorEl)}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {isMultiSelect &&
            selectionType !== ActionValueCriteriaEnum.Variable ? (
              <MenuItem
                className="!font-medium"
                onClick={onDetermineSelectionType(
                  ActionValueCriteriaEnum.Variable,
                )}
              >
                <span className="font-medium mr-4">
                  Determine selection by variable
                </span>
              </MenuItem>
            ) : null}
            {!isMultiSelect ||
            selectionType === ActionValueCriteriaEnum.Variable ? (
              <MenuItem
                className="!font-medium"
                // TODO: In case of multi choice we treat Variable selection as constant selection
                // ask manu for more info
                onClick={onDetermineSelectionType(
                  isMultiSelect ? null : ActionValueCriteriaEnum.Variable,
                )}
              >
                <span className="font-medium mr-4">
                  Determine selection by constant
                </span>
              </MenuItem>
            ) : null}
            {!isMultiSelect ? (
              <Tooltip
                arrow
                title="Determine the value of the action using AI decision-making. Contact sales to learn more!"
                hidden={selectionByConditionsEnabled}
              >
                <MenuItem
                  className="!font-medium"
                  disabled={!selectionByConditionsEnabled}
                  onClick={onDetermineSelectionType(
                    ActionValueCriteriaEnum.Condition,
                  )}
                >
                  <span className="font-medium mr-4">
                    Determine selection by conditions
                  </span>
                </MenuItem>
              </Tooltip>
            ) : null}
          </Menu>
        ) : null}

        {Boolean(isDownloadAction) || isDocumentScrape ? (
          <>
            <div className="text-sm mt-6 mb-2 text-gray-500">
              This downloaded file will be included as an output of a workflow
              execution.
            </div>

            {isDocumentScrape ? (
              <>
                <div className="mt-2 w-full bg-primary-blue-extralight flex space-x-2 px-4 py-3 rounded mb-4">
                  <InfoOutlined className="!w-5 !h-5 !text-[#0288D1] !mt-0.5" />
                  <span className="text-sm text-primary-blue-dark">
                    All files are compressed into a ZIP format. This will allow
                    you to rename the ZIP file while preserving the original
                    names and formats of the compressed files inside.
                  </span>
                </div>

                <div>
                  <VariableChip
                    onClick={() => {
                      onPreviewVariable(variable.id);
                    }}
                    variableId={variable.id}
                    variablesMap={variablesMap}
                    globalVariablesMap={globalVariablesMap}
                  />
                </div>
              </>
            ) : null}
          </>
        ) : null}
        {isMagicLoopAction ? (
          <>
            <span className="text-gray-500 text-sm my-4">
              A potential loop has been found during workflow processing.
            </span>
            <VariableInput
              allowAddVariable={false}
              value={variableData}
              label="Loop Name"
              onChange={setVariableData}
              placeholder="Add a name"
              variablesMap={variablesMap}
              globalVariablesMap={globalVariablesMap}
              showPlusButton={false}
            />
            <div className="mt-8 w-full bg-primary-blue-extralight flex space-x-2 px-4 py-3 rounded">
              <InfoOutlined className="!w-5 !h-5 !text-[#0288D1] !mt-0.5" />
              <span className="text-primary-blue-dark text-sm">
                Separate each item with a comma (",") for each of your variables
                while calling this using the “Run workflow” or the API.
              </span>
            </div>
          </>
        ) : null}
        {isPickFromList ? (
          <div className="flex flex-col space-y-2 text-sm mt-10">
            <b>Choose how to select the item(s) from the list</b>
            <span className="text-gray-500 font-medium">
              For complex actions or unsupported tasks, please share your
              instructions or requests, and we'll integrate them into your
              workflows.
            </span>
          </div>
        ) : null}
        {(selectionType === ActionValueCriteriaEnum.Variable ||
          isScrape ||
          isArbitraryAction ||
          (!selectionType && !criteria) ||
          (!selectionType && criteria === ActionValueCriteriaEnum.Variable)) &&
        !isDownloadAction &&
        !isMagicLoopAction &&
        !isDocumentScrape ? (
          <>
            <div className="my-10 border rounded-lg px-4 py-6 text-sm flex flex-col">
              {isScrape ? (
                <span className="text-gray-500 mb-6">
                  Content scraped during the execution is automatically
                  transformed into a variable. Click to preview its content.
                </span>
              ) : null}

              {!isSwitchTab && !isNewTab ? (
                <span className="font-medium mb-4 text-info-dark">Value</span>
              ) : null}

              {isMultiSelect &&
              selectionType === ActionValueCriteriaEnum.Variable ? (
                <span className="text-gray-500 mb-6">
                  Tie the values of the multiselect to a variable. If multiple
                  values may be checked, separate the values in the variable
                  with semicolons or commas. Variable value(s) are matched (best
                  fit) to multi-select values (case insensitive).
                </span>
              ) : null}

              {isSelect ? (
                <span className="text-gray-500 mb-6">
                  You can modify the selected option by adding variables that
                  match the items in the dropdown.
                </span>
              ) : null}

              {isInput ? (
                <VariableInput
                  allowAddVariable={
                    hasDatasource ||
                    sourceType === SourceTypeEnum.API ||
                    sourceType === SourceTypeEnum.EmailTrigger
                  }
                  className="mt-4"
                  value={variableData}
                  onClickAddNew={onAddNewVariable}
                  onChange={setVariableData}
                  onClickVariableChip={onPreviewVariableV2}
                  variablesMap={variablesMap}
                  globalVariablesMap={globalVariablesMap}
                  ref={inputVariableInputRef}
                />
              ) : null}

              {isMultiChoice ? (
                <MultipleChoice
                  className="mt-4"
                  data={variableData}
                  onChange={setVariableData}
                  optionEditable={isAdmin}
                  options={(variable as MultiChoiceVariable).multiChoiceOptions}
                  updateVariable={updateVariable}
                  variable={variable as MultiChoiceVariable}
                />
              ) : null}

              {isMultiSelect && !selectionType ? (
                <MultiSelect
                  onChange={updateVariable}
                  target={target}
                  variable={variable as MultiSelectVariable}
                />
              ) : null}

              {isMultiSelect &&
              selectionType === ActionValueCriteriaEnum.Variable ? (
                <VariableInput
                  allowAddVariable={
                    hasDatasource ||
                    sourceType === SourceTypeEnum.API ||
                    sourceType === SourceTypeEnum.EmailTrigger
                  }
                  className="mt-4"
                  value={variableData}
                  label="Add Variable"
                  onClickAddNew={onAddNewVariable}
                  onChange={setVariableData}
                  onClickVariableChip={onPreviewVariableV2}
                  placeholder="Add variable"
                  ref={inputVariableInputRef}
                  variablesMap={variablesMap}
                  globalVariablesMap={globalVariablesMap}
                />
              ) : null}

              {isPickFromList ? (
                <>
                  <SelectUIKit
                    className="mb-6"
                    color="secondary"
                    fullWidth
                    getLabel={(opt: string) => opt}
                    getValue={(opt: string) => opt}
                    label="Option(s) to pick"
                    name="option"
                    onChange={handleOnPickFromListSelect}
                    options={pickFromListOptions}
                    value={pickFromListType}
                  />
                  {pickFromListType === customInstructionsKey ? (
                    <div className="flex flex-col space-y-4 text-sm">
                      <p className="text-info-dark font-medium">
                        Describe how to pick an option from a list
                      </p>
                      <VariableInput
                        allowAddVariable={
                          hasDatasource ||
                          sourceType === SourceTypeEnum.API ||
                          sourceType === SourceTypeEnum.EmailTrigger
                        }
                        className="mt-4"
                        value={
                          variableData[0] === customInstructionsKey
                            ? []
                            : variableData
                        }
                        label="Instructions"
                        onClickAddNew={onAddNewVariable}
                        onChange={setVariableData}
                        onClickVariableChip={onPreviewVariableV2}
                        ref={inputVariableInputRef}
                        variablesMap={variablesMap}
                        globalVariablesMap={globalVariablesMap}
                      />
                    </div>
                  ) : null}
                </>
              ) : null}

              {isKeyPress ? (
                <SelectUIKit
                  className="mb-6"
                  color="secondary"
                  fullWidth
                  getLabel={(opt: string) => opt}
                  getValue={(opt: string) => opt}
                  label="Key Type"
                  name="key"
                  onChange={(e) => {
                    if (selectedNode) {
                      updateImageNodeAction(selectedNode, {
                        ...action,
                        keyType: e.target.value as KeyTypesEnum,
                      });
                    }
                  }}
                  options={keyPressOptions}
                  value={action.keyType}
                />
              ) : null}

              {isSelect ? (
                <Select
                  allowAddVariable={
                    hasDatasource ||
                    sourceType === SourceTypeEnum.API ||
                    sourceType === SourceTypeEnum.EmailTrigger
                  }
                  className="mt-4"
                  data={variableData}
                  label={variable.name ?? ''}
                  onAddNew={onAddNewVariable}
                  onChange={setVariableData}
                  onPreview={onPreviewVariableV2}
                  options={(variable as SelectVariable).selectOptions}
                  variablesMap={variablesMap}
                  globalVariablesMap={globalVariablesMap}
                />
              ) : null}

              {variable.type === VariableTypeEnum.Document ? (
                <div>
                  <SelectUIKit
                    className="mb-6"
                    color="secondary"
                    fullWidth
                    getLabel={(opt: string) => {
                      if (opt === 'aws') return 'Upload Local File';
                      return 'File URL';
                    }}
                    getValue={(opt: string) => opt}
                    label="File source"
                    name="source"
                    onChange={handleOnSelectSource}
                    options={['aws', 'url']}
                    value={localSource}
                  />

                  {localSource === DocumentSourceEnum.AWS ? (
                    <div>
                      <p className="font-medium mb-4 text-info-dark">
                        Local file
                      </p>

                      <p className="text-gray-500 mb-4">
                        Upload a file from your device. <br />
                        Supported formats: PDF, PNG, JPEG
                      </p>

                      <input
                        accept=".pdf,.png,.jpg"
                        className="hidden display-none"
                        onChange={handleFileSelect}
                        ref={fileInputRef}
                        type="file"
                      />
                      {pickedFile ? (
                        <div className="w-full rounded-lg border-2 border-slate-200 p-3 px-3 relative">
                          <div
                            className={clsx(
                              'flex gap-2',
                              pickedFile.uploading && 'mb-3',
                            )}
                          >
                            <DescriptionOutlined className="mt-1 text-cyan-900" />
                            <div className="flex flex-col justify-center">
                              <span className="text-cyan-900 font-medium leading-tight">
                                {pickedFile.name}
                              </span>
                              {pickedFile.size ? (
                                <p className="text-slate-500 text-xs font-normal leading-none">
                                  {pickedFile.size}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          <CloseIcon
                            className="absolute top-2 right-2 cursor-pointer"
                            color="secondary"
                            onClick={() => {
                              setPickedFile(null);
                              updateVariable({
                                ...variable,
                                data: {
                                  ...variable.data,
                                  s3Ref: undefined,
                                },
                              });
                            }}
                          />

                          {pickedFile.uploading ? (
                            <LinearProgress color="secondary" />
                          ) : null}
                        </div>
                      ) : (
                        <Button
                          color="secondary"
                          onClick={() => {
                            fileInputRef.current?.click();
                          }}
                          variant="outlined"
                        >
                          Upload file
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div>
                      <VariableInput
                        allowAddVariable={
                          hasDatasource ||
                          sourceType === SourceTypeEnum.API ||
                          sourceType === SourceTypeEnum.EmailTrigger
                        }
                        className="mt-4"
                        value={variableData}
                        label="File URL link"
                        onClickAddNew={onAddNewVariable}
                        onChange={setVariableData}
                        onClickVariableChip={onPreviewVariableV2}
                        variablesMap={variablesMap}
                        globalVariablesMap={globalVariablesMap}
                      />
                    </div>
                  )}
                </div>
              ) : null}

              {isScrape ? (
                <div className="">
                  <VariableChip
                    onClick={() => {
                      const varId = variable.id;
                      onPreviewVariableV2(varId);
                    }}
                    variableId={variable.id}
                    variablesMap={variablesMap}
                    globalVariablesMap={globalVariablesMap}
                  />
                </div>
              ) : null}

              {variable.type === VariableTypeEnum.Tab && isNewTab ? (
                <div>
                  <p className="text-cyan-900 text-sm font-medium mb-2">
                    Tab name
                  </p>
                  <p className="text-slate-500 text-sm mb-8">
                    Add a recognizable name to this tab.
                    <br />
                    The name will be required to switch between tabs.
                  </p>

                  <Input
                    classes={{ wrapper: 'mb-8' }}
                    floatingLabel
                    label="Tab name"
                    onChange={setVariableName}
                    value={variableName}
                  />

                  <p className="text-cyan-900 text-sm font-medium">
                    New tab URL
                  </p>

                  <VariableInput
                    allowAddVariable={
                      hasDatasource || sourceType === SourceTypeEnum.API
                    }
                    className="mt-4"
                    value={variableData}
                    ref={tabVariableInputRef}
                    label="Tab URL"
                    onChange={setVariableData}
                    onClickAddNew={onAddNewVariable}
                    onClickVariableChip={onPreviewVariableV2}
                    variablesMap={variablesMap}
                    globalVariablesMap={globalVariablesMap}
                  />
                </div>
              ) : null}

              {variable.type === VariableTypeEnum.Tab && isSwitchTab ? (
                <div>
                  <p className="text-cyan-900 text-sm font-medium mb-2">
                    Tab to switch
                  </p>
                  <p className="text-slate-500 text-sm mb-8">
                    Select the tab you want to switch to
                  </p>

                  <SelectUIKit<SelectOption>
                    color="secondary"
                    fullWidth
                    getLabel={(opt: SelectOption) => opt.text}
                    getValue={(opt: SelectOption) => opt.value}
                    label="Select action type"
                    name="actionType"
                    onChange={(e) => {
                      setSelectedTabVariableId(e.target.value as string);
                    }}
                    options={tabVariableOptions}
                    value={selectedTabVariableId as never}
                  />

                  <div className="grow flex-col pt-1.5 mt-8 bg-gray-100 rounded-tl-lg rounded-tr-lg w-full">
                    <p className="text-xs text-gray-500 px-3">
                      URL Value preview
                    </p>
                    <p className="text-base text-gray-500 mt-1 px-3 mb-1.5 truncate">
                      {switchActionPreview}
                    </p>
                    <div className="border border-dashed border-slate-300" />
                  </div>
                </div>
              ) : null}
            </div>

            {isScrape && isAdmin ? (
              <div className="border rounded-lg px-4 py-6 flex justify-between items-center text-sm font-medium">
                <span className="text-info">Continue if the scrape fails</span>
                <Switch
                  color="secondary"
                  size="small"
                  checked={continueOnFail}
                  onChange={(_, newValue) => {
                    if (selectedNode && selectedAction) {
                      updateImageNodeAction(selectedNode, {
                        ...selectedAction,
                        options: {
                          ...selectedAction.options,
                          continueOnFail: newValue,
                        },
                      });
                    }
                  }}
                />
              </div>
            ) : null}
          </>
        ) : null}
        {selectionType === ActionValueCriteriaEnum.Condition ||
        (!selectionType && criteria === ActionValueCriteriaEnum.Condition) ? (
          <ConditionalField
            addVariable={addVariable}
            datasourceMetadata={datasourceMetadata}
            defaultRules={rules}
            onCancel={() => {
              setSelectionType(null);
            }}
            onSave={(_rules: Rule[]) => {
              if (!selectedNode) return;
              const updatedAction = {
                ...action,
                rules: _rules,
                criteria: ActionValueCriteriaEnum.Condition,
              };
              updateImageNodeAction(selectedNode, updatedAction);
              setSelectedAction({ ...updatedAction, i });
              setSelectionType(null);
              saveVariableChanges(updatedAction);
              setSelectedAction(null);
            }}
            onTransformApiReq={onTransformApiReq}
            preview={!selectionType}
            tableData={tableData}
            transformApiReqStatus={transformApiReqStatus}
            sourceType={sourceType}
            updateVariable={updateVariable}
            variable={variable}
            variablesMap={variablesMap}
            globalVariablesMap={globalVariablesMap}
          />
        ) : null}
        {variableIsEditable ? (
          <>
            <span className="flex-1" />
            <Button
              className="!text-info !border-info !mt-5"
              color="secondary"
              onClick={() => {
                saveVariableChanges();
                setSelectedAction(null);
              }}
              variant="outlined"
            >
              Save & Exit
            </Button>
          </>
        ) : null}

        {Boolean(isDownloadAction) || isDocumentScrape ? (
          <>
            <span className="flex-1" />
            <Flex className="gap-2">
              <Button
                className="!text-info !border-info !mt-5 flex-1"
                color="secondary"
                onClick={() => {
                  setSelectedAction(null);
                }}
                variant="outlined"
              >
                BACK TO ACTIONS
              </Button>
            </Flex>
          </>
        ) : null}
      </div>
    </>
  );
}

export default EditAction;
