import { v4 as uuid } from 'uuid';
import {
  ActionsEnum,
  type BranchData,
  BranchModeEnum,
  BulkCheckableNodeTypes,
  type Comparator,
  type Condition,
  DocumentSourceEnum,
  DocumentVariable,
  getRootEndNodes,
  GmailTriggerVariableEnum,
  Group,
  type MultiChoiceVariable,
  type MultiSelectVariable,
  type NodeData,
  NodeStatusEnum,
  NodeTypesEnum,
  QueryValueTypeEnum,
  ScrapeTypeEnum,
  ScrapeVariable,
  type SelectVariable,
  SubVariableExtractor,
  type TabVariable,
  TargetMap,
  type TemplateVariable,
  type Variable,
  VariableMap,
  VariableString,
  VariableTypeEnum,
  type WorkflowAction,
  WorkflowConditionalNode,
  type WorkflowData,
  WorkflowEdge,
  WorkflowImageNode,
  WorkflowNode,
  type ZodSchema,
} from 'types-shared';
import {
  type Connection,
  type Edge,
  type EdgeChange,
  MarkerType,
} from 'types-shared/reactflow';
import isNil from 'lodash/isNil';
import startCase from 'lodash/startCase';
import keyBy from 'lodash/keyBy';
import entries from 'lodash/entries';
import { autoFormat } from './autoformat';
import { modalEventChannel } from 'ui-kit';
import { createTemplateVariable } from '../components/EditNodePanel/request.helpers';
import { AdminVersionEnum } from 'api-types-shared';
import { handleException } from 'sentry-browser-shared';
import pick from 'lodash/pick';
import values from 'lodash/values';

export const formatLabelString = (variableData: Variable): string => {
  const { type } = variableData;
  switch (type) {
    default:
      return JSON.stringify(variableData);
  }
};

export const isUUID = (str: string) => {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(str);
};

/**
 * Creates email trigger variables if they do not already exist in the variables map.
 *
 * @param sourceVariableId - The ID of the source variable.
 * @param variablesMap - A map of existing variables.
 * @returns An array of new email trigger variables.
 */
export const createEmailTriggerVariables = (
  sourceVariableId: string,
  variablesMap: Record<string, Variable>,
): Variable[] => {
  // Extract existing variable names from the variables map
  const existingVariableNames = new Set(
    Object.values(variablesMap)
      .map((variable) => variable.name)
      .filter<string>((name): name is string => typeof name === 'string'),
  );

  // Filter out email trigger variable names that already exist
  // and create new variables for the remaining names
  return Object.values(GmailTriggerVariableEnum)
    .filter((variableName) => !existingVariableNames.has(variableName))
    .map((variableName) => ({
      id: uuid(),
      type: VariableTypeEnum.Query,
      name: variableName,
      data: {
        valueType: QueryValueTypeEnum.String,
        query: [''],
        sourceIds: [sourceVariableId],
      },
      dashboardData: {
        initialValue: `The "${variableName}" of the email that triggers this workflow`,
      },
    }));
};

export const showVersionDiffDialog = (onConfirm: CallableFunction) => {
  modalEventChannel.emit('open', {
    title: 'Outdated version',
    descriptions: [
      'You are using an outdated version of this workflow. Please download the latest changes from cloud.',
    ],
    actions: [
      {
        text: 'Download Cloud Changes',
        onClick: () => {
          onConfirm();
          modalEventChannel.emit('close');
        },
      },
    ],
  });
};

type EdgeLabel = string | undefined;

export const getAllNodesAfter = (
  node: WorkflowNode,
  nodes: WorkflowNode[],
  edges: WorkflowEdge[],
): string[] => {
  const visitedNodeIds = new Set<string>();
  const result: string[] = [];

  const traverseNodes = (currentNode: WorkflowNode) => {
    if (!visitedNodeIds.has(currentNode.id)) {
      visitedNodeIds.add(currentNode.id);
      result.push(currentNode.id);
      const nextNodeIds = edges
        .filter((edge) => edge.source === currentNode.id)
        .map((edge) => edge.target);

      nextNodeIds.forEach((nextNodeId) => {
        const nextNode = nodes.find((item) => item.id === nextNodeId);
        if (nextNode) traverseNodes(nextNode);
      });
    }
  };

  traverseNodes(node);
  return result;
};

export const findSiblingNodeIds = (
  sourceNode: WorkflowNode,
  _edges: WorkflowEdge[],
) => {
  return _edges
    .filter((edge) => edge.source === sourceNode.id)
    .map((edge) => edge.target)
    .filter((e) => !isNil(e));
};

export const comparatorToLabel = (comparator: Comparator) => {
  const comparatorLabel = startCase(comparator);
  return comparatorLabel.charAt(0) + comparatorLabel.substring(1).toLowerCase();
};

export const insertNodeAfter = (
  source: WorkflowNode | WorkflowEdge,
  nodes: WorkflowNode[],
  edges: WorkflowEdge[],
  setFunctions: {
    setNodes: (nodes: WorkflowNode[]) => void;
    setEdges: (edges: WorkflowEdge[]) => void;
  },
  branch = false,
) => {
  let sourceNode: WorkflowNode | undefined;
  let intermediateNode: WorkflowNode;
  let targetNode: WorkflowNode | undefined;
  const sourceEdgeParse = WorkflowEdge.safeParse(source);
  const nodeLabelMap: Record<string, string> = {};
  const labelProps = {
    label: 'Branch 1',
    labelStyle: { display: 'none' },
  };
  const edgeId = uuid();

  let updatedEdges = [...edges];
  if (sourceEdgeParse.success) {
    const sourceEdge = sourceEdgeParse.data;
    sourceNode = nodes.find((node) => node.id === sourceEdge.source);
    if (!sourceNode) {
      throw new Error('sourceNode not found');
    }
    targetNode = nodes.find((node) => node.id === sourceEdge.target);
    intermediateNode = {
      id: uuid(),
      position: { ...sourceNode.position },
      type: NodeTypesEnum.New,
      data: { nodeStatus: NodeStatusEnum.NotViewed },
      width: 256,
      height: 232,
    };
    const newEdgesToAdd = [
      { ...sourceEdge, target: intermediateNode.id },
      ...(targetNode
        ? [
            {
              id: edgeId,
              source: intermediateNode.id,
              target: targetNode.id,
              ...labelProps,
            },
          ]
        : []),
    ];
    updatedEdges = updatedEdges
      .filter((edge) => edge.id !== sourceEdge.id)
      .concat(newEdgesToAdd);
  } else {
    sourceNode = WorkflowNode.parse(source);
    intermediateNode = {
      id: uuid(),
      position: { ...sourceNode.position },
      type: NodeTypesEnum.New,
      data: { nodeStatus: NodeStatusEnum.NotViewed },
      width: 256,
      height: 232,
    };

    const newEdge: WorkflowEdge = {
      id: edgeId,
      source: sourceNode.id,
      target: intermediateNode.id,
    };

    if (!branch) {
      const outgoingEdges = updatedEdges.filter(
        (edge) => edge.source === sourceNode?.id,
      );
      const newEdgesToAdd = outgoingEdges.map((edge) => ({
        ...edge,
        ...labelProps,
        source: intermediateNode.id,
        label: (edge.label as EdgeLabel) ?? labelProps.label,
      }));
      updatedEdges = updatedEdges
        .filter(
          (edge) =>
            !outgoingEdges.some((outgoingEdge) => outgoingEdge.id === edge.id),
        )
        .concat(newEdgesToAdd);
    } else {
      const siblingNodeIds = findSiblingNodeIds(sourceNode, edges);
      if (siblingNodeIds.length === 1) {
        const siblingEdge = edges.find(
          (edge) =>
            edge.source === sourceNode?.id && edge.target === siblingNodeIds[0],
        );
        if (!siblingEdge) {
          throw new Error('siblingEdge not found');
        }
        nodeLabelMap[siblingEdge.id] =
          (siblingEdge.label as EdgeLabel) ?? `Branch 1`;
      }
      nodeLabelMap[newEdge.id] =
        `Branch ${(siblingNodeIds.length + 1).toString()}`;
    }
    updatedEdges.push(newEdge);
  }
  const updatedNodes = [...nodes, intermediateNode];
  const nodePositions = autoFormat(
    updatedNodes,
    updatedEdges,
    setFunctions.setNodes,
  );
  setFunctions.setEdges(
    updatedEdges.map((edge) =>
      nodeLabelMap[edge.id]
        ? {
            ...edge,
            label: nodeLabelMap[edge.id],
            labelStyle: { display: 'block' },
          }
        : edge,
    ),
  );
  return {
    nodeId: intermediateNode.id,
    edgeId,
    nodePositions,
  };
};

/**
 * Merges selected nodes and edges into the last node.
 * If user selects nodes A, B, C, D, E, and selects merge,
 * the nodes and their action data will be merged into E.
 */
export const mergeSelectedNodes = (
  nodes: WorkflowImageNode[],
  edges: WorkflowEdge[],
): { newNodes: WorkflowNode[]; newEdges: WorkflowEdge[] } => {
  const nodesMap: Record<string, WorkflowNode> = nodes.reduce(
    (acc, node) => ({
      ...acc,
      [node.id]: node,
    }),
    {},
  );
  const sourceNode = nodes.find(
    (node) => (node as WorkflowNode).type === NodeTypesEnum.Source,
  );
  if (!sourceNode) {
    return { newNodes: nodes, newEdges: edges };
  }
  const nodeIds = getAllNodesAfter(sourceNode, nodes, edges);
  const selectedNodes: WorkflowImageNode[] = nodeIds
    .map((id) => nodesMap[id] as WorkflowImageNode)
    .filter((node) => node.data.selected);
  const lastSelectedNode = selectedNodes[selectedNodes.length - 1];
  const mergedSelectedNode = selectedNodes
    .slice(0, -1)
    .reduceRight((acc, node) => {
      const newAcc = { ...acc };
      newAcc.data = {
        ...newAcc.data,
        actionData: { ...node.data.actionData, ...newAcc.data.actionData },
        actionOrder: [...node.data.actionOrder, ...newAcc.data.actionOrder],
        nodeUrls: [...newAcc.data.nodeUrls, ...node.data.nodeUrls],
      };
      return newAcc;
    }, lastSelectedNode);

  let newNodes: WorkflowNode[] = [...nodes].map((node) => ({
    ...node,
    data: {
      ...(node.id === mergedSelectedNode.id
        ? mergedSelectedNode.data
        : node.data),
      selected: false,
    },
  }));
  let newEdges: WorkflowEdge[] = [...edges];

  for (const node of selectedNodes) {
    if (node.id === mergedSelectedNode.id) continue;
    const data = removeNode(newNodes, newEdges, node.id);
    newNodes = data.nodes;
    newEdges = data.edges;
  }

  return { newNodes, newEdges };
};

export const checkSelectedNodes = (
  nodes: WorkflowImageNode[],
): WorkflowNode[] => {
  return nodes.map((node) => {
    if (!BulkCheckableNodeTypes.includes(node.type)) return node;
    return {
      ...node,
      data: {
        ...node.data,
        selected: false,
        nodeStatus: (node.data as { selected: boolean }).selected
          ? NodeStatusEnum.Checked
          : NodeStatusEnum.NotViewed,
      },
    };
  }) as WorkflowNode[];
};

export const replaceNodeWithSection = (
  previousWorkflowData: WorkflowData,
  sectionWorkflowData: WorkflowData,
  replaceNodeId: string,
): {
  newNodes: WorkflowNode[];
  newEdges: WorkflowEdge[];
} => {
  const { sourceNodes, sinkNodes } = getRootEndNodes(
    sectionWorkflowData.nodes,
    sectionWorkflowData.edges,
  );
  if (sourceNodes.length !== 1 || sinkNodes.length !== 1) {
    throw new Error('Invalid number of root and sink nodes found');
  }
  const sourceNode = sourceNodes[0];
  const sinkNode = sinkNodes[0];

  const modifiedEdges = previousWorkflowData.edges
    .map((edge) => {
      if (edge.target === replaceNodeId) {
        return { ...edge, target: sourceNode.id };
      } else if (edge.source === replaceNodeId) {
        return { ...edge, source: sinkNode.id };
      }
      return edge;
    })
    .filter(
      (edge) => edge.source !== replaceNodeId && edge.target !== replaceNodeId,
    );
  const newEdges = [...modifiedEdges, ...sectionWorkflowData.edges];

  const filteredNodes = previousWorkflowData.nodes.filter(
    (node) => node.id !== replaceNodeId,
  );
  const newNodes = [...filteredNodes, ...sectionWorkflowData.nodes];

  return { newNodes, newEdges };
};

export const convertLeadingTrailingSpaces = (str: string): string => {
  // Regular expression to match leading and trailing spaces
  const leadingSpaces = /^\s+/.exec(str);
  const trailingSpaces = /\s+$/.exec(str);

  let leadingNbsp = '';
  let trailingNbsp = '';

  if (leadingSpaces) {
    leadingNbsp = leadingSpaces[0].replace(/ /g, '&nbsp;');
  }

  if (trailingSpaces) {
    trailingNbsp = trailingSpaces[0].replace(/ /g, '&nbsp;');
  }

  // Replace the leading and trailing spaces in the original string
  const trimmedStr = str.trim();
  return leadingNbsp + trimmedStr + trailingNbsp;
};

export const constructVariable = (
  variableId: string,
  variableActionType: ActionsEnum,
  options?: string[],
  action?: WorkflowAction,
): Variable | undefined => {
  const variable = { id: variableId };
  switch (variableActionType) {
    case ActionsEnum.Scrape: {
      const scrapeVariable: ScrapeVariable = {
        ...variable,
        type: VariableTypeEnum.Scrape,
        name: `New Scrape: ${uuid()}`,
        data: {
          selector: {
            cssPath: 'body',
            coordinates: {
              x: 0,
              y: 0,
              width: 50,
              height: 50,
            },
          },
          scrapedText: '',
          outputType: ScrapeTypeEnum.InnerText,
          excludeFromOutputs: false,
        },
      };
      return scrapeVariable;
    }
    case ActionsEnum.UploadDocument: {
      const uploadVariable: DocumentVariable = {
        ...variable,
        data: {
          source: DocumentSourceEnum.AWS,
          url: [''],
        },
        type: VariableTypeEnum.Document,
      };
      return uploadVariable;
    }
    case ActionsEnum.MultiChoice: {
      const multiChoiceVariable: MultiChoiceVariable = {
        ...variable,
        type: VariableTypeEnum.MultiChoice,
        multiChoiceOptions: (options ?? []).map((option) => ({
          ariaLabel: option,
          coordinates: {
            x: 0,
            y: 0,
            width: 50,
            height: 50,
          },
          cssPath: '',
        })),
        selectedChoiceIx: 0,
        data: ['0'],
      };
      return multiChoiceVariable;
    }
    case ActionsEnum.Select: {
      const selectVariable: SelectVariable = {
        ...variable,
        type: VariableTypeEnum.Select,
        selectOptions: options
          ? options.map((option) => ({
              value: option,
              text: option,
            }))
          : [],
        data: [(options ?? [''])[0]],
      };
      return selectVariable;
    }
    case ActionsEnum.SwitchTab: {
      const tabVariable: TabVariable = {
        ...variable,
        name: 'Tab',
        type: VariableTypeEnum.Tab,
        data: {
          url: [''],
        },
      };
      return tabVariable;
    }
    case ActionsEnum.NewTab: {
      const tabVariable: TabVariable = {
        ...variable,
        name: 'Tab',
        type: VariableTypeEnum.Tab,
        data: {
          url: [''],
        },
      };
      return tabVariable;
    }
    case ActionsEnum.MultiSelect: {
      const multiSelectVariable: MultiSelectVariable = {
        ...variable,
        type: VariableTypeEnum.MultiSelect,
        multiSelectedOptions: [],
        data: [],
      };
      return multiSelectVariable;
    }

    default: {
      if (
        variableActionType === ActionsEnum.Click &&
        action?.options?.download
      ) {
        const documentVariable: DocumentVariable = {
          ...variable,
          type: VariableTypeEnum.Document,

          data: {
            source: DocumentSourceEnum.Execution,
          },
        };
        return documentVariable;
      }

      const isPickFromListAction =
        variableActionType === ActionsEnum.PickFromList;
      const val = isPickFromListAction ? 'Select the first option' : '';
      const templateVariable: TemplateVariable = {
        ...variable,
        type: VariableTypeEnum.Template,
        data: [val],
      };
      return templateVariable;
    }
  }
};

export const createTarget = (targetId: string) => ({
  id: targetId,
  ref: {
    role: '',
    innerText: '',
    cssPath: '',
    directInnerText: '',
    coordinates: {
      x: 0,
      y: 0,
      width: 10,
      height: 10,
    },
  },
  coordinates: {
    xPercent: 0,
    yPercent: 0,
    widthPercent: 10,
    heightPercent: 10,
  },
});

export const cloneNodeData = (data: Partial<NodeData>): Partial<NodeData> => {
  if (!('actionData' in data)) {
    return data;
  }
  const { actionData, actionOrder } = data as NodeData;
  const newActions = actionOrder.map((actionId) => {
    const action = actionData[actionId];
    const newActionId = uuid();

    return {
      ...action,
      id: newActionId,
    };
  });
  return {
    ...data,
    actionOrder: newActions.map((action) => action.id),
    actionData: keyBy(newActions, 'id') as Record<string, WorkflowAction>,
  };
};

const getParsedValueFromString = <T>(
  valueString: string,
  schema: ZodSchema<T>,
  defaultValue: T,
): T => {
  const parsedValue = JSON.parse(valueString) as T;
  if (!schema.safeParse(parsedValue).success) {
    handleException(new Error('Invalid string while deep cloning variable'), {
      name: 'deepCloneVariable',
      source: 'deepCloneVariable',
      extra: {
        valueString,
        parsedValue,
        defaultValue,
      },
    });
    return defaultValue;
  }
  return parsedValue;
};

const cloneTargets = (
  nodes: WorkflowNode[],
  targets: TargetMap,
): {
  nodes: WorkflowNode[];
  targets: TargetMap;
} => {
  let targetsString = JSON.stringify(targets);
  let nodesString = JSON.stringify(nodes);
  entries(targets).forEach(([targetId]) => {
    const newTargetId = uuid();
    targetsString = targetsString.replaceAll(targetId, newTargetId);
    nodesString = nodesString.replaceAll(targetId, newTargetId);
  });
  return {
    nodes: getParsedValueFromString(nodesString, WorkflowNode.array(), nodes),
    targets: getParsedValueFromString(targetsString, TargetMap, targets),
  };
};

type WorkflowClipboardData = WorkflowData & {
  targets: TargetMap;
  variables: VariableMap;
};

const deepCloneVariable = (
  variable: Variable,
  variables: VariableMap,
  nodes: WorkflowNode[],
): {
  variables: VariableMap;
  nodes: WorkflowNode[];
} => {
  let variablesString = JSON.stringify(variables);
  let nodesString = JSON.stringify(nodes);
  const newVariableId = uuid();
  const oldVariableId = variable.id;
  const name = variable.name;
  variablesString = variablesString.replaceAll(oldVariableId, newVariableId);
  if (name) {
    variablesString = variablesString.replaceAll(name, `${name} (copy)`);
  }
  nodesString = nodesString.replaceAll(oldVariableId, newVariableId);
  return {
    nodes: getParsedValueFromString(nodesString, WorkflowNode.array(), nodes),
    variables: getParsedValueFromString(
      variablesString,
      VariableMap,
      variables,
    ) as VariableMap,
  };
};

const cloneVariables = (
  nodes: WorkflowNode[],
  variables: VariableMap,
): {
  nodes: WorkflowNode[];
  variables: VariableMap;
} => {
  let newVariablesMap = { ...variables };
  let newNodes = [...nodes];
  values(variables).forEach((variable) => {
    const result = deepCloneVariable(variable, newVariablesMap, newNodes);
    newNodes = result.nodes;
    newVariablesMap = result.variables;
  });
  return {
    nodes: newNodes,
    variables: newVariablesMap,
  };
};

const variableTypesToSkipShallowCopy = [
  VariableTypeEnum.Scrape,
  VariableTypeEnum.Document,
];

const shallowCloneVariables = (
  nodes: WorkflowNode[],
  variables: VariableMap,
): {
  nodes: WorkflowNode[];
  variables: VariableMap;
} => {
  const updatedVariablesMap = {
    ...variables,
  };
  const updatedNodes = nodes.map((node) => {
    if (!WorkflowImageNode.safeParse(node).success) {
      return node;
    }
    const { actionData } = node.data as NodeData;
    const actions = values(actionData);
    const newActions = actions.map((action) => {
      if (!action.variableId) {
        return action;
      }
      const variable = updatedVariablesMap[action.variableId];
      if (variableTypesToSkipShallowCopy.includes(variable.type)) {
        return action;
      }
      const newVariableId = uuid();
      updatedVariablesMap[newVariableId] = {
        ...variable,
        id: newVariableId,
      };
      return {
        ...action,
        variableId: newVariableId,
      };
    });
    return {
      ...node,
      data: {
        ...node.data,
        actionData: keyBy(newActions, 'id') as Record<string, WorkflowAction>,
      },
    };
  }) as WorkflowNode[];
  const scrapeAndDocumentVariables = updatedNodes
    .flatMap((node) => {
      if (!WorkflowImageNode.safeParse(node).success) {
        return [];
      }
      const { actionData } = node.data as NodeData;
      return values(actionData).map((action) => action.variableId);
    })
    .filter((variableId) => {
      if (!variableId) {
        return false;
      }
      const variable = updatedVariablesMap[variableId];
      return (
        ScrapeVariable.safeParse(variable).success ||
        DocumentVariable.safeParse(variable).success
      );
    }) as string[];
  const scrapeAndDocumentVariablesMap = pick(
    updatedVariablesMap,
    scrapeAndDocumentVariables,
  );
  const result = cloneVariables(updatedNodes, scrapeAndDocumentVariablesMap);
  return {
    nodes: result.nodes,
    variables: {
      ...updatedVariablesMap,
      ...result.variables,
    },
  };
};

export function transformPastedWorkflow(
  workflowData: WorkflowClipboardData,
  isPastedInSameWorkflow: boolean,
  deepCopyVariables = true,
): {
  workflowData: WorkflowData;
  targetMap: TargetMap;
  variableMap: VariableMap;
} {
  const nodeIdsMapping: Record<string, string> = {};
  for (const node of workflowData.nodes) {
    nodeIdsMapping[node.id] = uuid();
  }
  const newWorkflowData = {
    ...workflowData,
    nodes: workflowData.nodes.map((node) => {
      const data = cloneNodeData(node.data);
      return {
        ...node,
        id: isPastedInSameWorkflow ? nodeIdsMapping[node.id] : node.id,
        data,
        position: {
          ...node.position,
          x: node.position.x + 20,
          y: node.position.y + 20,
        },
      };
    }) as WorkflowNode[],
    edges: workflowData.edges.map((edge) => ({
      ...edge,
      id: isPastedInSameWorkflow ? uuid() : edge.id,
      source: isPastedInSameWorkflow
        ? nodeIdsMapping[edge.source]
        : edge.source,
      target: isPastedInSameWorkflow
        ? nodeIdsMapping[edge.target]
        : edge.target,
    })),
  };
  const { nodes: nodesUpdatedWithTargets, targets: newTargets } = cloneTargets(
    newWorkflowData.nodes,
    workflowData.targets,
  );
  const copyFn = deepCopyVariables ? cloneVariables : shallowCloneVariables;
  const { nodes: nodesUpdatedWithVariables, variables: newVariables } = copyFn(
    nodesUpdatedWithTargets,
    workflowData.variables,
  );
  newWorkflowData.nodes = nodesUpdatedWithVariables;
  return {
    workflowData: newWorkflowData,
    variableMap: newVariables,
    targetMap: newTargets,
  };
}

export function createWorkflowData({
  workflowId,
  targets,
  variables,
  globalVariables,
  nodes,
  selectedNodes,
  selectedEdges,
}: {
  workflowId: string;
  targets: TargetMap;
  variables: VariableMap;
  globalVariables: VariableMap;
  nodes: WorkflowNode[];
  selectedNodes: WorkflowNode[];
  selectedEdges: WorkflowEdge[];
}) {
  const selectedNodeIds = selectedNodes.map((node) => node.id);

  const targetIds = selectedNodes.flatMap((node) => {
    if (!('actionData' in node.data)) {
      return [];
    }
    return values(node.data.actionData).map((action) => action.targetId);
  });
  const selectedTargets = pick(targets, targetIds as string[]);

  const workflowVariables = extractGlobalVariablesFromTemplates(
    variables,
    globalVariables,
    nodes,
  );

  const variableExtractor = new SubVariableExtractor(
    workflowVariables,
    handleException,
  );
  const extractedVariables =
    variableExtractor.extractVariablesFromNodes(selectedNodes);
  const variableIds = Object.keys(extractedVariables);

  const selectedVariables = pick(variables, Array.from(variableIds));
  if (selectedNodeIds.length === 0 && selectedEdges.length === 0) {
    return;
  }
  return {
    workflowId,
    nodes: selectedNodes,
    edges: selectedEdges,
    variables: selectedVariables,
    targets: selectedTargets,
  };
}

export function parseWorkflowDataFromClipboardData(
  clipboardText: string,
): WorkflowClipboardData | undefined {
  if (clipboardText.includes('"nodes":')) {
    try {
      return JSON.parse(clipboardText) as WorkflowClipboardData;
    } catch (e) {
      // eslint-disable-next-line
      console.log('Failed to parse copied data', clipboardText, e);
    }
  }
  return undefined;
}

export const pickFromListOptions = [
  'Select the first option',
  'Closest match is selected',
  'Describe with instructions',
];

const defaultMarker = {
  type: MarkerType.Arrow,
  width: 10,
  height: 10,
  strokeWidth: 2,
  color: '#000',
};

export const syncBranches = (
  nodes: WorkflowNode[],
  edges: WorkflowEdge[],
  addVariable: (newVar: Variable) => void,
): {
  nodes: WorkflowNode[];
  edges: WorkflowEdge[];
} => {
  const updatedNodes = nodes.map((node) => {
    if (node.type === NodeTypesEnum.Conditional) {
      const conditionalNodeEdgeIds = edges
        .filter((edge) => edge.source === node.id)
        .map((edge) => edge.id);
      const branchesData = (node.data.branchesData ?? []).filter((branch) =>
        conditionalNodeEdgeIds.includes(branch.branchId),
      );

      const branchEdgeIds = branchesData.map((branch) => branch.branchId);
      const newBranchesData = [
        ...branchesData,
        ...conditionalNodeEdgeIds
          .filter((edgeId) => !branchEdgeIds.includes(edgeId))
          .map((edgeId) => {
            const oldBranchData = branchesData.find(
              (b) => b.branchId === edgeId,
            );

            return {
              branchId: edgeId,
              selectedMode: oldBranchData?.selectedMode ?? BranchModeEnum.Rule,
              instruction: oldBranchData?.instruction ?? {
                variableId: createTemplateVariable(addVariable).id,
              },
              ...(oldBranchData?.rule ? { rule: oldBranchData.rule } : {}),
            };
          }),
      ];
      if (newBranchesData.length > 0) {
        return {
          ...node,
          data: {
            ...node.data,
            branchesData: newBranchesData,
          },
        };
      }
    }
    return node;
  });
  return {
    nodes: updatedNodes,
    edges,
  };
};

export const addBranch = (
  nodes: WorkflowNode[],
  connection: Connection,
  addVariable: (newVar: Variable) => void,
): {
  nodes: WorkflowNode[];
  edge: Connection;
} => {
  const edge = {
    ...connection,
    markerEnd: defaultMarker,
  };
  let newNodes = nodes;
  const sourceNode = nodes.find((node) => node.id === connection.source);
  const { source, target } = connection;
  const isSourceNodeConditional =
    sourceNode?.type === NodeTypesEnum.Conditional;
  if (isSourceNodeConditional && source && target) {
    newNodes = nodes.map((_node) => {
      if (_node.id === sourceNode.id) {
        const node = _node as WorkflowConditionalNode;
        const oldBranchesData = node.data.branchesData ?? [];
        const branchesData = [
          ...oldBranchesData,
          {
            branchId: `reactflow__edge-${source}-${target}`,
            selectedMode: BranchModeEnum.Rule,
            instruction: { variableId: createTemplateVariable(addVariable).id },
          } as BranchData,
        ];
        (edge as Edge).label = `New Branch ${branchesData.length.toString()}`;
        return {
          ...node,
          data: {
            ...node.data,
            branchesData,
          },
        };
      }
      return _node;
    });
  }
  return {
    nodes: newNodes,
    edge,
  };
};

export const removeBranch = (
  changes: EdgeChange[],
  nodes: WorkflowNode[],
): WorkflowNode[] => {
  if (changes.length > 0) {
    const [change] = changes;
    if (change.type === 'remove') {
      return nodes.map((node) => {
        if (node.type === NodeTypesEnum.Conditional) {
          const branchesData = node.data.branchesData?.filter(
            (data) => data.branchId !== change.id,
          );
          return {
            ...node,
            data: {
              ...node.data,
              branchesData,
            },
          };
        }
        return node;
      });
    }
  }
  return nodes;
};

const stripNewlinesFromVariable = (
  variable: TemplateVariable,
): TemplateVariable => {
  return {
    ...variable,
    data: variable.data.map((val, i) => {
      if (i === variable.data.length - 1 && typeof val === 'string') {
        return val.replace(/\n+$/, '');
      }
      return val;
    }),
  };
};

const stripNewlinesFromCondition = (
  el: Condition | Group,
  variableMap: VariableMap,
  updateVariable: (varToUpdate: Variable) => void,
): void => {
  if (Group.safeParse(el).success) {
    stripNewlinesFromGroup(el as Group, variableMap, updateVariable);
  } else {
    const conditionEl = el as Condition;

    // Strip field;
    updateVariable(
      stripNewlinesFromVariable(
        variableMap[conditionEl.field.variableId] as TemplateVariable,
      ),
    );

    // Strip value:
    updateVariable(
      stripNewlinesFromVariable(
        variableMap[conditionEl.value.variableId] as TemplateVariable,
      ),
    );
  }
};

export const stripNewlinesFromGroup = (
  group: Group,
  variableMap: VariableMap,
  updateVariable: (varToUpdate: Variable) => void,
): void => {
  group.elements.forEach((el) => {
    stripNewlinesFromCondition(el, variableMap, updateVariable);
  });
};

export const removeNode = (
  nodes: WorkflowNode[],
  edges: WorkflowEdge[],
  deleteNodeId: string,
): {
  nodes: WorkflowNode[];
  edges: WorkflowEdge[];
} => {
  const incomingEdge = edges.find((e) => e.target === deleteNodeId);
  const outgoingEdge = edges.find((e) => e.source === deleteNodeId);
  const filteredNodes = nodes
    .filter((node) => node.id !== deleteNodeId)
    .map((node) => {
      if (
        node.type === NodeTypesEnum.Conditional &&
        WorkflowConditionalNode.safeParse(node).success &&
        !outgoingEdge
      ) {
        const nodeData = node.data;
        return {
          ...node,
          data: {
            ...nodeData,
            branchesData: nodeData.branchesData?.filter(
              (b) => b.branchId !== incomingEdge?.id,
            ),
          },
        };
      }
      return node;
    });
  const filteredEdges = edges
    .filter((edge) => edge.source !== deleteNodeId)
    .filter((edge) => outgoingEdge ?? edge.target !== deleteNodeId)
    .map((edge) => {
      if (edge.target === deleteNodeId && outgoingEdge) {
        return {
          ...edge,
          target: outgoingEdge.target,
        };
      }
      return edge;
    });

  return {
    nodes: filteredNodes,
    edges: filteredEdges,
  };
};

export const getQueryParam = (key: string): string | null => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(key);
};

/**
 * Merges the variables in the workflow with global variables and returns an updated map.
 *
 * @param variables - The variables in the workflow.
 * @param globalVariablesMap - All global variables.
 * @param nodes - The nodes in the workflow.
 * @returns An updated variable map that includes any missing global variables and excludes any orphaned global variables.
 */
export function extractGlobalVariablesFromTemplates(
  variables: VariableMap,
  globalVariablesMap: VariableMap,
  nodes: WorkflowNode[],
): VariableMap {
  const variablesStr = JSON.stringify(variables);
  const nodeVariablesStr = JSON.stringify(nodes);
  const globalVariableStr = JSON.stringify(globalVariablesMap);
  const globalVariableIds = Object.keys(globalVariablesMap);

  // Remove unused global variables.
  const filteredVariableMap: VariableMap = {};
  const globalVarsInVarMap: VariableMap = {};

  for (const variableId of Object.keys(variables)) {
    if (!globalVariableStr.includes(variableId)) {
      filteredVariableMap[variableId] = variables[variableId];
    } else {
      globalVarsInVarMap[variableId] = variables[variableId];
    }
  }

  const filteredVariableMapStr = JSON.stringify(filteredVariableMap);

  for (const includedGlobalVarId of Object.keys(globalVarsInVarMap)) {
    if (filteredVariableMapStr.includes(includedGlobalVarId)) {
      filteredVariableMap[includedGlobalVarId] =
        globalVarsInVarMap[includedGlobalVarId];
    }
  }

  const globalVariablesInNodes = globalVariableIds.some((id) =>
    nodeVariablesStr.includes(id),
  );

  const globalVariablesInVariableMap = globalVariableIds.some((id) =>
    variablesStr.includes(id),
  );

  const hasGlobalVariables =
    globalVariablesInVariableMap || globalVariablesInNodes;
  if (!hasGlobalVariables) {
    return filteredVariableMap;
  }

  // Add any missing global variables
  for (const globalVarId of globalVariableIds) {
    const isIncluded =
      nodeVariablesStr.includes(globalVarId) ||
      variablesStr.includes(globalVarId);
    if (isIncluded) {
      filteredVariableMap[globalVarId] = globalVariablesMap[globalVarId];
    }
  }

  return filteredVariableMap;
}

export const getWorkflowVersionType = (
  workflowMetadata?: { currentVersionCommitUsers?: string[] } | null,
): {
  isNotifyPush: boolean;
  isSilentPush: boolean;
  isErrorPush: boolean;
} => {
  const result = {
    isNotifyPush: false,
    isSilentPush: false,
    isErrorPush: false,
  };
  if (!workflowMetadata) {
    handleException(new Error(), {
      name: 'Workflow metadata not found.',
      source: 'Editor/getWorkflowVersionType',
    });
    return result;
  }
  const commitUsers = workflowMetadata.currentVersionCommitUsers ?? [];
  if (commitUsers.includes(AdminVersionEnum.SilentPush)) {
    result.isSilentPush = true;
    return result;
  }
  if (commitUsers.includes(AdminVersionEnum.ForcePush)) {
    result.isErrorPush = true;
    return result;
  }
  result.isNotifyPush = true;
  return result;
};

export const checkIfVariableHasTransformations = (variable: Variable) => {
  const transformedValue = VariableString.parse(
    variable.dashboardData?.transformInputs?.transformedValue ?? '',
  );
  const query = variable.dashboardData?.transformInputs?.query;
  return Boolean(
    (transformedValue && transformedValue.length > 0) ||
      (query && query.length > 0 && query[0]),
  );
};
