import { WorkflowStatusEnum, SignalTypeEnum } from 'api-types-shared';
import {
  ExecutionStatusEnum,
  ExecutionTriggerEnum,
} from 'types-shared/executionTypes';
import type { WorkflowStatusSelectItem } from '../pages/Workflows/types';
import {
  CancelOutlinedIcon,
  CheckCircleOutline,
  NearbyErrorIcon,
  DeleteOutlineIcon,
  FullscreenOutlinedIcon,
  PauseCircleOutlinedIcon,
  PlayCircleOutlineIcon,
} from 'ui-kit';

export const EMPTY_DATA_URL = 'data:,';

export const VERCEL_STUB_PATH =
  'https://j1vv3wdqxwe6zvfn.public.blob.vercel-storage.com/apps/sola-dashboard/public/stubs';
export const DEFAULT_STUB = 'UpdatedBusinessFilingStub';
export const STUB_WORKFLOW_ID = 'stub';

export const DEFAULT_REFETCH_INTERVAL = 60 * 1000;
export const EXECUTION_METRICS_REFETCH_INTERVAL = 20 * 1000;
export const EXECUTIONS_LIST_REFETCH_INTERVAL = 20;
export const EXECUTIONS_LIST_REFETCH_INTERVAL_IN_MS =
  EXECUTIONS_LIST_REFETCH_INTERVAL * 1000;
export const DEFAULT_CACHE_TIME = 3 * 60 * 1000;

export const DATASOURCE_FILE_MAX_SIZE = 10 * 1000 * 1000;

export const DASHBOARD_URL = 'https://dashboard.sola.ai';

export const CONTACT_SLACK_CHANNEL_ID = 'C06G8CTGLLD';

export const TRANSFORM_INPUT_CHAR_LIMIT = 3000;

// Feature flags are configured in Posthog
// See https://us.posthog.com/project/42697/feature_flags
export enum FeatureFlag {
  LocalRuns = 'localRuns',
  NewExecutionDetails = 'newExecutionDetails',
  RemoteExecutionApiTrigger = 'remoteExecutionApiTrigger',
  IntegrationsPage = 'integrationsPage',
  ApiKeysManager = 'apiKeyManager',
  EndingStatusBlock = 'endingStatusBlock',
  Datasource = 'datasource',
  AutolinkDemo = 'autolinkDemo',
  SelectionByConditions = 'selectionByConditions',
  HideRunId = 'hideRunID',
  ContinueRecordingBlock = 'continueRecordingBlock',
  ImportWorkflow = 'importWorkflow',
  EditWorkflow = 'editWorkflow',
  LiveExecutionView = 'liveExecutionView',
  MorganUsers = 'morganUsers',
  ExecutionScreenshotDetails = 'executionScreenshotDetails',
  ApiPageRetryConfig = 'apiPageRetryConfig',
  ShowAllNodeLogs = 'allNodeLogs',
  OutputDetailsExportButton = 'outputDetailsExportButton',
  ExtensionPageRedirect = 'extensionPageRedirect',
  FullRequestNodeVersion = 'fullRequestNodeVersion',
  ManualRun = 'manualRun',
  EnableWorkflowCloning = 'enableCloning',
  RetryBlock = 'retryBlock',
  FreeformBlock = 'freeformBlock',
  FrontendSandboxImportLimit = 'frontend-sandbox-limit-import',
  ExecutionDetails = 'executionDetails',
  GlobalVariables = 'sharedVariablesPage',
  DemoFeatures = 'demoFeatures',
  EditDownloads = 'editDownloads',
  InsightsPage = 'insightsPage',
  ExecutionsFilters = 'executionsFilters',
  ExecutionAndWorkflowListMetrics = 'executionAndWorkflowListMetrics',
  ScheduleRun = 'scheduleRun',
  HitlLiveView = 'hitlLiveView',
  ReadonlyWorkflows = 'readOnlyWorkflows',
  SystemVariables = 'systemVariables',
  ExtensionCookies = 'extension-cookies',
  ExtensionLocalStorage = 'extension-localStorage',
  WebhookPage = 'webhooksPage',
  UploadRawExtensionData = 'backendProcessing-uploadRawExtensionData',
  AllowCloningBranches = 'allowCloningBranches',
  ActionHandledBySola = 'actionHandledBySola',
  CancelExecutions = 'cancelExecutions',
}

export const triggerTitleMapping: Record<string, string> = {
  [ExecutionTriggerEnum.API]: ExecutionTriggerEnum.API,
  [ExecutionTriggerEnum.Manual]: 'Manual',
};

export const allowedUserExecutionStatuses = [
  ExecutionStatusEnum.Success,
  ExecutionStatusEnum.Failed,
  ExecutionStatusEnum.Terminated,
  ExecutionStatusEnum.Timeout,
  ExecutionStatusEnum.Canceled,
  ExecutionStatusEnum.Retry,
];

export const statusTitleMapping: Record<string, string> = {
  [ExecutionStatusEnum.PendingUser]: 'Action needed',
  [ExecutionStatusEnum.PendingAdmin]: 'Running',
  [ExecutionStatusEnum.PendingSystem]: 'Running',
  [ExecutionStatusEnum.Terminated]: 'Failed',
  [ExecutionStatusEnum.Retry]: 'Waiting to retry',
};

export const workflowStatusSelectOptions: WorkflowStatusSelectItem[] = [
  {
    label: 'Processing',
    value: WorkflowStatusEnum.ProcessingImport,
  },
  {
    label: 'Processed',
    value: WorkflowStatusEnum.ProcessedImport,
  },
  {
    label: 'Processing Error',
    value: WorkflowStatusEnum.ProcessingImportError,
  },
  {
    label: 'Processing Error Authentication',
    value: WorkflowStatusEnum.ProcessingImportErrorAuthentication,
  },
];

export const workflowListStatusToTitleMap: Record<string, WorkflowStatusEnum> =
  {
    [WorkflowStatusEnum.Invalid]: WorkflowStatusEnum.ProcessedImport,
  };

export const executionSignalsConfig = [
  {
    Icon: PlayCircleOutlineIcon,
    tooltipText: 'Resume workflow at next action',
    signal: SignalTypeEnum.Resume,
  },
  {
    Icon: PauseCircleOutlinedIcon,
    tooltipText: 'Pause workflow at current action',
    signal: SignalTypeEnum.Pause,
  },
  {
    Icon: FullscreenOutlinedIcon,
    tooltipText: 'Capture screenshot of current view',
    signal: SignalTypeEnum.Screenshot,
  },
  {
    Icon: CheckCircleOutline,
    tooltipText: 'Stop workflow and mark as successful',
    signal: SignalTypeEnum.Done,
  },
  {
    Icon: CancelOutlinedIcon,
    tooltipText: 'Stop workflow and mark as failed',
    signal: SignalTypeEnum.Stop,
  },
  {
    Icon: DeleteOutlineIcon,
    tooltipText: 'Stop workflow, mark as terminated, and kill future retries',
    signal: SignalTypeEnum.Terminate,
  },
  {
    Icon: NearbyErrorIcon,
    tooltipText: 'Set auto error message',
    signal: SignalTypeEnum.SetAutoErrorMessage,
  },
];

export const executionStatusTitleMapping: Record<string, string> = {
  [ExecutionStatusEnum.PendingUser]: 'Action needed',
  [ExecutionStatusEnum.PendingAdmin]: 'Running',
  [ExecutionStatusEnum.PendingSystem]: 'Running',
  [ExecutionStatusEnum.Terminated]: 'Failed',
};

export const notAllowedExecutionSignalStatuses = [
  ExecutionStatusEnum.Failed,
  ExecutionStatusEnum.Terminated,
  ExecutionStatusEnum.Success,
];

export enum ChartColors {
  SUCCESS = '#1ad3a2',
  FAILURE = '#f03c56',
  RUNNING = '#0f3d61',
}

export const terminalStatuses = [
  ExecutionStatusEnum.Success,
  ExecutionStatusEnum.Failed,
  ExecutionStatusEnum.Timeout,
  ExecutionStatusEnum.Canceled,
  ExecutionStatusEnum.Terminated,
];

export const workflowProcessingStatuses = {
  allProcessing: [
    WorkflowStatusEnum.ProcessingImport,
    WorkflowStatusEnum.ProcessingImportErrorAuthentication,
    WorkflowStatusEnum.ProcessingImportError,
  ],
  processingAndInaccessible: [
    WorkflowStatusEnum.ProcessingImport,
    WorkflowStatusEnum.ProcessingImportError,
  ],
  processingAndAccessible: [
    WorkflowStatusEnum.ProcessingImportErrorAuthentication,
  ],
};

export const TEMPORAL_UI_ADDRESS_MAPPING: Record<string, string> = {
  development:
    'https://tut.dev.sola-solutions.com/namespaces/default/workflows',
  staging:
    'https://cloud.temporal.io/namespaces/staging-legacy-execution.riamh/workflows',
  production:
    'https://cloud.temporal.io/namespaces/production-legacy-execution.riamh/workflows',
  sandbox:
    'https://cloud.temporal.io/namespaces/sandbox-legacy-execution.riamh/workflows',
  mm: 'https://cloud.temporal.io/namespaces/mm-legacy-execution.riamh/workflows',
};
